.acts-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.acts-body-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Add space between the cards */
  padding: 20px; /* Add padding to avoid touching the screen edges */
}

@media (max-width: 1380px) {
  .acts-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .acts-header {
    font-size: 30px;
  }
}

/* Responsive styles for activity cards */
@media (max-width: 1200px) {
  .acts-body-div {
    justify-content: space-around; /* Adjust spacing on smaller screens */
  }
}

@media (max-width: 768px) {
  .acts-body-div {
    flex-direction: column;
    align-items: center; /* Center cards in column layout */
  }
}
