/* ImageGalleryModal.css */
.gallery-modal button {
  margin: 10px;
  padding: 5px 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.gallery-modal button:hover {
  background-color: #ccc;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in-out, visibility 0ms linear 300ms;
}

.modal-backdrop.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms ease-in-out;
}

.modal-content {
  max-width: 100%;
  max-height: 80%;
  margin-bottom: 20px;
  transition: opacity 300ms ease-in-out;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.modal-close-btn {
  margin: "0 10px";
  padding: "10px 20px";
  font-size: "16px";
  background-color: #fff; /*// Use body color from theme for background*/
  border: "2px solid #FFF";
  border-radius: "5px";
  cursor: "pointer";
}

.comments-column {
  flex: 1; /* Less space for comments */
  max-width: 50%; /* Adjust the width based on your preference */
  min-width: 200px; /* Ensures readability of comments */

  overflow-y: auto;
  margin-top: 1%;
  margin-right: 5%;
  padding: 20px; /* Provide padding to space out the text */
  text-align: justify;
}

.image-column {
  flex: 2; /* Gives more space to images */
  max-width: 50%; /* Adjust the width based on your preference */
  min-width: 300px; /* Ensures images are not too small */
  height: 90%; /* Adjust the height based on your preference */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .modal-backdrop {
    flex-direction: column;
    overflow-y: auto;
  }

  .image-column,
  .comments-column {
    width: 100%;
    max-width: 100%;
  }

  .image-column {
    max-height: 50%;
  }

  .comments-column {
    height: 60%;
  }
}
